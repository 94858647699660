import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Survey from "./survey/survey";
import WaitingForm from "./component_waiting";
import ErrorForm from "./component_error";
import parseUri from "./helpers/parseuri";
import auth_connector from "./api/auth";
import "bootstrap/dist/css/bootstrap.min.css";
import "./static/css/common.css";
import "./static/css/controls.css";
import "./static/css/forms.css";
import "./i18n";
import { decode } from "./helpers/base64decode";

import { checkTemplate } from "./helpers/template_utils";
window.uri_params = parseUri(window.location.search);

const renderReactComponent = (component) => {
  ReactDOM.render(component, document.getElementById("root"));
};

renderReactComponent(<WaitingForm />);

auth_connector.auth((err, res) => {
  if (err) {
    renderReactComponent(<ErrorForm error_msg={err.api_error.message} />);
    return;
  }
  let stats = {};
  if (window.uri_params.au_aid < 1) {
    auth_connector.load_template(window.uri_params.fid, (err, res) => {
      if (err) {
        console.log(err);
        if (err?.api_error?.message)
          renderReactComponent(<ErrorForm error_msg={err.api_error.message} />);
      } else {
        let template = {};
        let answers;
        window.whole_payload = res.qarr;
        if (window.whole_payload.retrn === "201")
          window.dest_entid = window.auth.pro_zo_entid;
        if (window.whole_payload.au_aid > 0)
          window.params.au_aid = window.whole_payload.au_aid;
        if (res.qarr.arr_fragen) {
          const json_template = decode(res.qarr.arr_fragen)?.replace(
            /"showOtherItem"/g,
            '"hasOther"'
          );

          template = JSON.parse(json_template);
          // console.log("data answer",answers)
          const template_check = checkTemplate(template);
          stats.no_of_quest = template_check.questions_info.qty;
          stats.no_of_quest_answered = 0;
        } else {
          template = {
            title: res.qarr.titel,
            description: decode(res.qarr.fbinfo, true),
          };
        }

        renderReactComponent(
          <Survey template={template} answers={answers} stats={stats} />
        );
      }
    });
  } else {
    auth_connector.load_survey((err, res) => {
      if (err) {
        console.log(err);
        if (err?.api_error?.message)
          renderReactComponent(<ErrorForm error_msg={err.api_error.message} />);
      } else {
        let template = {};
        let answers;

        window.whole_payload = res.qarr;

        if (res.qarr.arr_fragen) {
          const json_template = decode(res.qarr.arr_fragen)?.replace(
            /"showOtherItem"/g,
            '"hasOther"'
          );

          template = JSON.parse(json_template);

          const template_check = checkTemplate(template);

          answers = res.qarr.arr_antworten;
          stats.no_of_quest =
            res.qarr?.no_of_quest || template_check.questions_info.qty;
          stats.no_of_quest_answered = res.qarr?.no_of_quest_answered || 0;
        } else {
          template = {
            title: res.qarr.titel,
            description: decode(res.qarr.fbinfo, true),
          };
        }

        renderReactComponent(
          <Survey template={template} answers={answers} stats={stats} />
        );
      }
    });
  }
});

reportWebVitals();
