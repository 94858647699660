import ApiModel from "./../api/api_model";
import Connector from "./../api/http_connector";
import { checkTemplate } from "../helpers/template_utils";

const connector = new Connector({
  link: process.env.REACT_APP_API_LINK,
  endpoint: process.env.REACT_APP_ENDPOINT,
});

class apiModelAuth extends ApiModel {
  auth(callback) {
    if (!window.uri_params.otp_param && !window.uri_params.fid) {
      callback({ api_error: { message: "Data error: Keys dont exist" } });
      return;
    }
    const payload = {};
    payload.mount_name = "auth";
    payload.mount_type = "validate_OTP_keys";
    payload.params = {
      otp_param: window.uri_params.otp_param,
    };
    this._connectApi(payload, (err, res) => {
      if (!err) {
        window.uri_params.otp_param = res.api_data.otp_param_s64;
        window.token = res.api_data.token;
        window.auth = res.api_data.auth;
        callback(false, true);
      } else {
        console.log(err);
        if (err?.api_error?.message) callback(err);
        else callback({ message: "Auth: Wrong keys" });
      }
    });
  }
  load_survey(callback) {
    const payload = {};
    payload.auth = window.auth;
    payload.mount_name = "websurvey";
    payload.mount_type = "load_survey";
    payload.params = {
      fid: window.uri_params.fid,
      au_aid: window.uri_params.au_aid,
    };
    this._connectApi(payload, (err, res) => {
      if (!err) {
        callback(false, res.api_data);
      } else {
        callback(err, res);
      }
    });
  }
  load_template(fid, callback) {
    const payload = {};
    payload.auth = window.auth;
    payload.mount_name = "webtemplate";
    payload.mount_type = "load_template";
    payload.params = {
      fid: fid,
    };
    this._connectApi(payload, (err, res) => {
      if (!err) {
        //window.uri_params.otp_param = res.api_data.otp_param_s64;
        callback(false, res.api_data);
      } else {
        callback(err, res);
      }
    });
  }
  save_survey(template, answers, callback) {
    const payload = {};
    payload.auth = window.auth;
    payload.mount_name = "websurvey";
    payload.mount_type = "save_survey";
    payload.params = {
      fid: window.uri_params.fid,
      au_aid: window.uri_params.au_aid,
    };

    if (window.dest_entid) payload.params.dest_entid = window.dest_entid;
    const template_status = checkTemplate(template);
    const answered_qty = Object.keys(answers).length;
    const received_json = window.whole_payload;
    // console.log("answered_qty",answered_qty)
    //and inject dest_entid directly to structure
    if (window.dest_entid) received_json.dest_entid = window.dest_entid;
    received_json.arr_antworten = { ...received_json.arr_antworten, ...answers };
    // console.log("answers",answers)
    received_json.no_of_quest = template_status.questions_info.qty;
    received_json.no_of_quest_answered = answered_qty;
    const whole_payload_json_base64 = JSON.stringify(received_json);
    const data = { qarr_json64: whole_payload_json_base64 };
    payload.params.data = data;

    this._connectApi(payload, (err, res) => {
      if (!err) {
        //window.uri_params.otp_param = res.api_data.otp_param_s64;
        callback(false, res.api_data);
      } else {
        callback(err, res);
      }
    });
  }
}

const apiModel = new apiModelAuth(connector);

export default apiModel;
