import React, { useState, useEffect } from "react";
import "survey-react/survey.css";
import logo from "./../static/logo/logo-chmedic2.svg";
import "./../shared/survey/survey.css";
import auth_connector from "./../api/auth";
import Notification from "./../component_notification";
import notifications from "./../helpers/notifications";
import NativeDialog from "../custom_components/nativedialog";
import { useTranslation } from "react-i18next";
import { Button, Toggle1Icon, SelectField } from "./../components";

import {
  warningTemplate,
  templatePagesTitles,
  getTranslation,
} from "../helpers/template_utils";
import * as SurveyPlugin from "survey-react";
/* eslint-disable no-unused-vars */
import noUiSlider from "nouislider"; // instance register
import * as widgets from "surveyjs-widgets";
/* eslint-enable no-unused-vars */

import "nouislider/distribute/nouislider.min.css";

//old components
import leadmatrix4left from "./../custom_components/leadmatrix4left";
import leadmatrix4right from "./../custom_components/leadmatrix4right";
import leadmatrix8combined from "./../custom_components/leadmatrix8combined";
import inputInteger from "./../custom_components/inputinteger";
import inputPercents from "./../custom_components/inputpercents";

//v2
import nouislider from "./../custom_widgets/nouislider";
import inputcustom from "./../custom_widgets/inputcustom";
import inputdate from "./../custom_widgets/inputdate";
import clearanswer from "./../custom_widgets/clearanswer";
import clearprobesection from "./../custom_widgets/clearprobesection";
//lead probes
import leadprobes4v2 from "./../custom_components/leadprobes4v2";
import leadprobes8v2 from "./../custom_components/leadprobes8v2";
//lead matrix
import leadmatrix8v2 from "./../custom_components/leadmatrix8v2";

import pacientAge from "../custom_components/pacient_age";

SurveyPlugin.Serializer.addProperty("survey", {
  name: "shortVersion:boolean",
  category: "general",
  visibleIndex: 1,
  visible: true,
});

SurveyPlugin.Serializer.addProperty("survey", {
  name: "utf8:boolean",
  category: "general",
  visibleIndex: 0,
  visible: false,
});

SurveyPlugin.Serializer.addProperty("question", {
  name: "questionNumberingOverride:string",
  category: "general",
  visibleIndex: 1,
  visible: true,
});

SurveyPlugin.Serializer.addProperty("question", {
  name: "hideAnsweredStatus:boolean",
  category: "general",
});

SurveyPlugin.Serializer.addProperty("question", {
  name: "key:text",
  category: "general",
});

//Expression
const getPacientBirthdate = function () {
  return undefined; //for testing
};

SurveyPlugin.FunctionFactory.Instance.register(
  "getPacientBirthdate",
  getPacientBirthdate
);

const getProcedureDate = function () {
  return undefined; //for testing
};

SurveyPlugin.FunctionFactory.Instance.register(
  "getProcedureDate",
  getProcedureDate
);

function calcFullYearDifference(params) {
  if (!params || !params[0] || !params[1]) return undefined;

  let dateArray = params[0].split("-");
  const birthdate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  dateArray = params[1].split("-");
  const proceduredate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);

  let yearDifference = proceduredate.getFullYear() - birthdate.getFullYear();

  if (
    birthdate.getMonth() > proceduredate.getMonth() ||
    (birthdate.getMonth() === proceduredate.getMonth() &&
      birthdate.getDate() > proceduredate.getDate())
  ) {
    yearDifference--;
  }

  return yearDifference;
}

SurveyPlugin.FunctionFactory.Instance.register(
  "calcFullYearDifference",
  calcFullYearDifference
);

SurveyPlugin.Serializer.addProperty("itemvalue", {
  name: "calcValue",
});

const getCalcValue = function (params) {
  let question = !!this.row
    ? this.row.getQuestionByColumnName(params[0])
    : this.survey.getQuestionByName(params[0])
    ? this.survey.getQuestionByName(params[0])
    : this.question;

  if (!question) return 0;

  const questionType = question.getType();

  if (questionType === "inputcustom") {
    return parseFloat(question.value);
  }

  let choice = question.selectedItem;

  return !!choice ? Number(choice.calcValue) : 0;
};

//keeped for existing surveys
SurveyPlugin.FunctionFactory.Instance.register("getCalcValue", getCalcValue);
SurveyPlugin.FunctionFactory.Instance.register("gcv", getCalcValue);

const getPacientAge = function (params) {
  return null;
};

SurveyPlugin.FunctionFactory.Instance.register("getPacientAge", getPacientAge);

function nullCounter(value) {
  let counter = 0;

  if (value === undefined || value === null) {
    counter++;
  } else if (Array.isArray(value)) {
    for (var i = 0; i < value.length; i++) {
      counter += nullCounter(value[i]);
    }
  }

  return counter;
}

const countNulls = function (params) {
  let counter = nullCounter(params);

  return counter;
};

SurveyPlugin.FunctionFactory.Instance.register("countNulls", countNulls);

const roundFunc = function (params) {
  if (!isFinite(params[0])) return params[0];

  const exponent = Math.pow(10, params[1]);
  return Math.round(params[0] * exponent) / exponent;
};

SurveyPlugin.FunctionFactory.Instance.register("round", roundFunc);

function isDateLessThanCurrent(params) {
  if (params.length < 1) return false;
  var date = params[0];
  if (typeof date == "string") date = Date.parse(date);
  return date <= new Date();
}

SurveyPlugin.FunctionFactory.Instance.register(
  "isDateLessThanCurrent",
  isDateLessThanCurrent
);

//old
SurveyPlugin.JsonObject.metaData.addProperty("questionbase", "qnum");
SurveyPlugin.ComponentCollection.Instance.add(leadmatrix4left);
SurveyPlugin.ComponentCollection.Instance.add(leadmatrix4right);
SurveyPlugin.ComponentCollection.Instance.add(leadmatrix8combined);
SurveyPlugin.ComponentCollection.Instance.add(inputInteger);
SurveyPlugin.ComponentCollection.Instance.add(inputPercents);

//v2
SurveyPlugin.CustomWidgetCollection.Instance.add(nouislider, "customtype");
SurveyPlugin.CustomWidgetCollection.Instance.add(inputcustom, "customtype");
SurveyPlugin.CustomWidgetCollection.Instance.add(inputdate, "customtype");
SurveyPlugin.CustomWidgetCollection.Instance.add(clearanswer, "customtype");

//lead probes
SurveyPlugin.ComponentCollection.Instance.add(leadprobes4v2);
SurveyPlugin.ComponentCollection.Instance.add(leadprobes8v2);
//lead matrix
SurveyPlugin.ComponentCollection.Instance.add(leadmatrix8v2);

SurveyPlugin.ComponentCollection.Instance.add(pacientAge);

//pass data to widgets
inputcustom(SurveyPlugin);
nouislider(SurveyPlugin);
inputdate(SurveyPlugin);
clearanswer(SurveyPlugin);
clearprobesection(SurveyPlugin);

//boolean true during saving
let busy = false;
let initSaved = false;

const Survey = (props) => {
  const { t } = useTranslation();
  const presetPage =
    window.uri_params?.startpage && window.uri_params?.startpage > 0
      ? window.uri_params?.startpage - 1
      : 0;
  const [surveyPage, updateSurveyPage] = useState(presetPage);
  const [isCompleted, setCompleted] = useState(false);
  const [isStarted, surveyStarted] = useState(false);
  const [surveyShortVersion, setShortSurveyVersion] = useState(
    window.uri_params?.sv === "short"
  );

  const [answerState, setAnswerState] = useState(props.answers);

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: scrollTop,
      behavior: "instant",
    });
  }, [scrollTop, answerState]);

  const isExpression = (question) => {
    return question?.getType() === "expression";
  };

  const isAdmin = () => {
    return window.auth.adm_su_pcom === "100";
  };

  const hasRequiredQuestions = (surveyQuestions) => {
    if (!surveyQuestions.pages) {
      return false;
    }

    for (let p = 0; p < surveyQuestions.pages.length; p++) {
      const pageElements = surveyQuestions.pages[p].elements;

      if (!Array.isArray(pageElements)) continue;

      for (let e = 0; e < pageElements.length; e++) {
        const pageElement = pageElements[e];

        if (pageElement.isRequired || pageElement.isShortIncluded) return true;
      }
    }

    return false;
  };

  const preInitDataChanges = (surveyQuestions) => {
    setQuestionNumber(surveyQuestions);
    return processSurveyPages(surveyQuestions);
  };

  const processSurveyPages = (surveyQuestions) => {
    if (!surveyQuestions.pages) {
      return surveyQuestions;
    }

    const surveyData = { ...surveyQuestions };

    if (!surveyShortVersion || !hasRequiredQuestions(surveyQuestions)) {
      surveyData.pages = surveyData.pages.filter((f) => {
        return f.elements;
      });

      return surveyData;
    }

    var singlePage = { elements: [] };

    for (let p = 0; p < surveyQuestions.pages.length; p++) {
      const pageElements = surveyQuestions.pages[p].elements;

      if (!Array.isArray(pageElements)) continue;

      for (let e = 0; e < pageElements.length; e++) {
        const pageElement = pageElements[e];

        if (!pageElement.isRequired && !pageElement.isShortIncluded) continue;

        singlePage.elements.push(pageElements[e]);
      }
    }

    surveyData.pages = [singlePage];

    return surveyData;
  };

  const setQuestionNumber = (surveyQuestions) => {
    if (!surveyQuestions.pages) {
      return surveyQuestions;
    }

    for (let p = 0; p < surveyQuestions.pages.length; p++) {
      const pageElements = surveyQuestions.pages[p].elements;
      if (Array.isArray(pageElements)) {
        let questionCounter = 1;

        for (let e = 0; e < pageElements.length; e++) {
          let element = pageElements[e];

          // if (element.type === "expression") {
          //   continue;
          // }

          element.qnum = p + 1 + "." + questionCounter;

          questionCounter++;
        }
      }
    }

    return surveyQuestions;
  };

  const hasAtleastOneRequired = hasRequiredQuestions(props.template);

  const surveyData = preInitDataChanges(props.template);

  const survey_model = new SurveyPlugin.Model(surveyData);

  if (!!answerState) {
    for (const [key] of Object.entries(answerState)) {
      if (key?.endsWith("-Comment")) {
        survey_model.getQuestionByName(key.replace("-Comment", "")).comment =
          answerState[key];
        continue;
      }

      survey_model.setValue(key, answerState[key]);
    }
  }

  survey_model.locale = "de";

  const markAnsweredQuestion = (options) => {
    if (
      !options.question ||
      !options.question.id ||
      isExpression(options.question)
    ) {
      return;
    }

    const titleElement = document.getElementById(
      options.question.id + "_ariaTitle"
    );

    if (!titleElement || !titleElement.classList) {
      return;
    }

    if (options.question.getType() === "leadprobes8v2") {
      options.question.valueChangedCallback();
    }

    const additional_props_containter = document
      .getElementById(options.question.id)
      .getElementsByClassName("additional-properties")[0];

    if (
      options.question.hideAnsweredStatus !== true &&
      options.question.isAnswered &&
      (typeof options.question.value !== "object" ||
        (!(
          Array.isArray(options.question.value) &&
          options.question.value.length === 1 &&
          options.question.value[0] === null
        ) &&
          !options.question.value.nda))
    ) {
      titleElement.classList.add("--answered");

      if (additional_props_containter)
        additional_props_containter.classList.add("--answered");
    } else {
      titleElement.classList.remove("--answered");
      if (additional_props_containter)
        additional_props_containter.classList.remove("--answered");
    }
  };

  const saveStateOnValueChanged = (survey, options) => {
    const data = { ...survey.data };
    const question = options?.question;
    const questionType = question?.getType();

    if (options.name.endsWith("_nda")) {
      if (options.value) {
        data[options.name] = true;
      } else {
        delete data[options.name];
      }

      return;
    }

    //infiniteloop fix for expression and date jumping in year to end
    //as a result if page visibility is dependend on these question types we will have issues
    if (
      questionType &&
      questionType !== "expression" &&
      questionType !== "inputdate" &&
      questionType !== "nouislider" &&
      questionType !== "inputcustom" &&
      questionType !== "pacientage"
    ) {
      if (
        question.value === undefined ||
        (typeof question.value === "object" &&
          Array.isArray(question.value) &&
          question.value.length === 1 &&
          question.value[0] === null) ||
        (question.value.nda && Object.keys(question.value).length === 1)
      ) {
        data[question.name + "_nda"] = true;
      }

      const position = window.pageYOffset;
      setAnswerState(data);
      setScrollTop(position);
    }
  };

  const onSurveyAnswerChange = (survey, options) => {
    expressionsModification(options);
    markAnsweredQuestion(options);
    saveStateOnValueChanged(survey, options);
  };

  survey_model.showQuestionNumbers = "off";
  survey_model.showTitle = true;
  survey_model.showPageTitles = true;
  survey_model.showPageNumbers = false;
  survey_model.showProgressBar = "top";
  survey_model.showNavigationButtons = true;
  survey_model.focusFirstQuestionAutomatic = false;

  let astatus = Number(window.whole_payload?.astatus);
  let isReadOnly = astatus === 100;

  if (isReadOnly) {
    console.log("Read only mode");
    survey_model.mode = "display";
  }

  const setQuestionNumbering = (options) => {
    // if (!options.question.page || isExpression(options.question)) {
    //   return;
    // }

    const container_question = document.getElementById(options.question.id);

    if (!container_question) {
      return;
    }

    const qNumDiv = document.createElement("div");
    qNumDiv.className = "sv_q_num";
    qNumDiv.innerText = options.question.questionNumberingOverride
      ? options.question.questionNumberingOverride
      : options.question.qnum;

    container_question.prepend(qNumDiv);

    const additional_props_containter =
      container_question.getElementsByClassName("additional-properties")[0];

    if (!additional_props_containter) return;

    const additionalDiv = document.createElement("div");
    additionalDiv.className = "sv_q_add_info";

    additional_props_containter.prepend(additionalDiv);

    if (options.question.isRequired) {
      const requiredDiv = document.createElement("div");
      requiredDiv.className = "sv_q_required";
      requiredDiv.innerText = "*required";
      additionalDiv.append(requiredDiv);
    }

    const qIdDiv = document.createElement("div");
    qIdDiv.className = "sv_q_id";
    qIdDiv.innerText = "qid - " + options.question.name;

    additionalDiv.append(qIdDiv);
  };

  const radioGroupCustomizations = (options) => {
    if (options.question.getType() === "radiogroup") {
      const optionsContainer = document
        .getElementById(options.question.id)
        .getElementsByClassName("sv_qcbc");

      if (!optionsContainer || !optionsContainer.length > 0) {
        return;
      }

      if (!options.question.hasOther) return;

      optionsContainer[0].classList.add("has_other");
    }
  };

  const checkBoxCustomizations = (options) => {
    if (options.question.getType() === "checkbox") {
      const optionsContainer = document
        .getElementById(options.question.id)
        .getElementsByClassName("sv_qcbc");

      if (!optionsContainer || !optionsContainer.length > 0) {
        return;
      }

      if (options.question.hasOther)
        optionsContainer[0].classList.add("has_other");

      const additionalText = document.createElement("div");
      additionalText.innerText = "**" + t("Multiple Choice");

      optionsContainer[0].insertAdjacentElement("afterend", additionalText);
    }
  };

  const expressionsModification = (options) => {
    if (!isExpression(options.question)) {
      return false;
    }

    let val = options.question.value?.toString()?.replace(",", ".");

    if (!isFinite(val)) {
      return true;
    }

    const minimum = options.question.minimumFractionDigits;
    const maximum =
      minimum > options.question.maximumFractionDigits
        ? minimum
        : options.question.maximumFractionDigits;

    if (val > "" && maximum > -1) {
      let dec_multiplier = maximum > 0 ? 10 ** maximum : 1;
      val = Math.round(val * dec_multiplier) / dec_multiplier;
    }

    val = val?.toString();

    const indexPos = val?.indexOf(".");

    if (indexPos > 0) {
      val = val.padEnd(val.indexOf(".") + minimum + 1, "0");
    }

    options.question.value = val;

    return true;
  };

  survey_model.onSettingQuestionErrors.add((sender, options) => {
    for (var i = 0; i < options.errors.length; i++) {
      if (options.errors[i].getErrorType() === "required") {
        if (!options.question.survey.getValue(options.question.name + "_nda"))
          return;

        options.errors.splice(i, 1);
      }
    }
  });

  survey_model.onAfterRenderQuestion.add((survey_model, options) => {
    setQuestionNumbering(options);
    markAnsweredQuestion(options);
    checkBoxCustomizations(options);
    radioGroupCustomizations(options);
  });

  survey_model.onAfterRenderPage.add((survey_model, options) => {
    const customTitleToRemove = document
      .getElementsByClassName("sv_p_root")[0]
      .getElementsByClassName("sv_page_title custom")[0];
    if (customTitleToRemove) customTitleToRemove.remove();
    if (!options.page.title) {
      const hasTitle = document
        .getElementsByClassName("sv_p_root")[0]
        .getElementsByClassName("sv_page_title")[0];
      if (hasTitle) {
        hasTitle.innerText = options.page.name;
        return;
      }
      const pageRoot = document
        .getElementById(options.page.id)
        .getElementsByClassName("sv_p_root")[0];
      const newTitle = document.createElement("h4");
      newTitle.classList.add("sv_page_title");
      newTitle.classList.add("custom");
      newTitle.innerText = options.page.name;
      pageRoot.insertBefore(newTitle, pageRoot.firstChild);
    }

    //template checker
    const status = warningTemplate(surveyData);
    const container_header_warning =
      document.getElementsByClassName("header-warning");
    if (!container_header_warning[0]) return;
    container_header_warning[0].style.display = status ? "block" : "none";
    container_header_warning[0].innerText = status;
  });

  survey_model.onUpdateQuestionCssClasses.add((survey_model, options) => {
    if (!expressionsModification(options)) {
      return;
    }

    let classes = options.cssClasses;
    classes.root = "sv_q_text_root expression";
  });

  const initialSave = (answers, callback) => {
    if (initSaved || isReadOnly) {
      callback(false, true);
      return;
    }

    astatus = 10;
    window.whole_payload.astatus = 10;

    auth_connector.save_survey(props.template, answers.data, (err, res) => {
      if (!err) {
        initSaved = true;
        if (res?.qarr) window.whole_payload = res?.qarr;
        window.uri_params.au_aid = window.whole_payload?.au_aid;
      } else {
        notifications.error_top_full(null, "Survey not saved to DB");
      }
      callback(err, res);
    });
  };

  const onComplete = (answers) => {
    if (busy) return;

    if (isReadOnly) {
      return notifications.error_top_full(null, "Survey is in readonly mode");
    }

    busy = true;

    //this replace the answer data with the answerstate
    // answers.data = answerState;

    initialSave(answers, (err, res) => {
      if (err) {
        busy = false;
        return;
      }

      window.whole_payload.astatus = 100;
      astatus = 100;

      auth_connector.save_survey(
        props.template,
        !answers?.data ? {} : answers.data,
        (err, res) => {
          if (!err) {
            notifications.success_top_full(null, "Survey saved to DB");

            window.whole_payload = res.qarr;
            window.uri_params.au_aid = window.whole_payload.au_aid;

            const container_footer = document.getElementsByClassName("footer");
            container_footer[0].innerText = `fid:${window.uri_params.fid} | au_aid:${window.uri_params?.au_aid} | entid: ${window.whole_payload?.dest_entid} | auid: ${window.uri_params.auid} | qty: ${res?.qarr?.no_of_quest}/${res?.qarr?.no_of_quest_answered} | lc_template: ${window.whole_payload?.lc_template}`;
          } else {
            notifications.error_top_full(null, "Survey not saved to DB");
          }

          busy = false;
        }
      );
      setCompleted(true);
    });
  };

  const onSaveSurvey = (answers) => {
    if (busy) return;

    if (isReadOnly) {
      return notifications.error_top_full(null, "Survey is in readonly mode");
    }

    busy = true;

    initialSave(answers, (err, res) => {
      if (err) {
        busy = false;
        return;
      }

      window.whole_payload.astatus = 10;
      astatus = 10;

      auth_connector.save_survey(
        props.template,
        !answers?.data ? {} : answers.data,
        (err, res) => {
          if (!err) {
            notifications.success_top_full(null, "Survey saved to DB");

            window.whole_payload = res.qarr;
            window.uri_params.au_aid = window.whole_payload.au_aid;

            const container_footer = document.getElementsByClassName("footer");
            container_footer[0].innerText = `fid:${window.uri_params.fid} | au_aid:${window.uri_params?.au_aid} | entid: ${window.whole_payload?.dest_entid} | auid: ${window.uri_params.auid} | qty: ${res?.qarr?.no_of_quest}/${res?.qarr?.no_of_quest_answered} | lc_template: ${window.whole_payload?.lc_template}`;
          } else {
            notifications.error_top_full(null, "Survey not saved to DB");
          }

          busy = false;
        }
      );
    });
  };

  const onPageSwitch = (answers, options) => {
    //here block page switch or save
    if (busy) return;
    busy = true;
    initialSave(answers, (err, res) => {
      if (err) {
        busy = false;
        return;
      }

      if (options.isNextPage || options.nextPage) {
        updateSurveyPage(surveyPage + 1);
      } else if (options.isPrevPage || options.prevPage) {
        updateSurveyPage(surveyPage - 1);
      }

      setAnswerState(answers.data);
      setScrollTop(0);

      if (isReadOnly) {
        console.log("Saving is disabled");
        return;
      }

      window.whole_payload.astatus = 10;
      astatus = 10;

      auth_connector.save_survey(props.template, answers.data, (err, res) => {
        if (!err) {
          notifications.success_top_full(null, "Survey saved to DB");

          if (res?.qarr) window.whole_payload = res?.qarr;
          window.uri_params.au_aid = window.whole_payload?.au_aid;
          const container_footer = document.getElementsByClassName("footer");
          container_footer[0].innerText = `fid:${window.uri_params.fid} | au_aid:${window.uri_params?.au_aid} | entid: ${window.whole_payload?.dest_entid} | auid: ${window.uri_params.auid} | qty: ${res?.qarr?.no_of_quest}/${res?.qarr?.no_of_quest_answered} | lc_template: ${window.whole_payload?.lc_template}`;
        } else {
          notifications.error_top_full(null, "Survey not saved to DB");
          //!!callback && callback(saveNo, false);
        }
        //here unblock page switch or save
        busy = false;
      });
    });
  };

  const showCustomCompletedMessage = () => {
    return (
      <div
        style={{
          maxWidth: "400px",
          margin: "auto",
        }}
      >
        <h3>{t("Thank you for completing the survey!")}</h3>
        <div>{window.whole_payload?.vartxt_abschluss}</div>
      </div>
    );
  };

  const SurveyFooter = () => {
    return (
      <div className="footer">
        {`fid:${window.uri_params.fid} | 
                au_aid:${window.uri_params.au_aid} | 
                entid: ${window.whole_payload?.dest_entid} | 
                auid: ${window.uri_params.auid} | 
                qty: ${props.stats?.no_of_quest}/${props.stats?.no_of_quest_answered} |
                lc_template: ${window.whole_payload?.lc_template}`}
      </div>
    );
  };

  const showCustomNav = () => {
    if (isCompleted) {
      return;
    }

    let prev_status = "enabled";
    let next_status = "hidden";
    let submit_status = "hidden";

    if (surveyPage === 0) {
      next_status =
        survey_model?.visiblePages?.length < 2 ? "hidden" : "enabled";
      submit_status =
        survey_model?.visiblePages?.length < 2 ? "enabled" : "hidden";
    } else {
      next_status =
        surveyPage === survey_model?.visiblePages?.length - 1
          ? "hidden"
          : "enabled";
      submit_status =
        surveyPage === survey_model?.visiblePages?.length - 1
          ? "enabled"
          : "hidden";
    }

    const showDropdownNav = () => {
      const pagesTitles = templatePagesTitles(survey_model);

      if (pagesTitles.length === 1) return null;

      return (
        <div className="toolbar-row dropdown-navigation">
          <SelectField
            className="search_filter"
            allowEmpty={false}
            name="survey_page"
            placeholder="Chapter"
            options={pagesTitles}
            onChange={(params) => {
              const newPage = survey_model.visiblePages[params.value];
              survey_model.currentPage = newPage;
              updateSurveyPage(params.value);
            }}
            value={surveyPage > 0 ? surveyPage : 0}
          />
        </div>
      );
    };

    return (
      <div className={"toolbar-row custom-navigation top"}>
        <Button
          status={prev_status}
          name="prev_page"
          label="Back"
          onClick={() => {
            if (surveyPage === 0) {
              setAnswerState(survey_model.data);
              surveyStarted(false);
              return;
            }

            survey_model.prevPage();
          }}
        />
        {showDropdownNav()}
        <div>
          {!isReadOnly && isAdmin() && (
            <Button
              name="save_survey"
              label="Save"
              style={{ backgroundColor: "#26b255", borderColor: "green" }}
              onClick={() => {
                onSaveSurvey(survey_model);
              }}
            />
          )}
          <Button
            status={next_status}
            name="next_page"
            label="Next"
            onClick={() => {
              survey_model.nextPage();
            }}
          />
          {!isReadOnly && (
            <Button
              status={submit_status}
              name="submit_survey"
              label="Complete"
              style={{ backgroundColor: "#26b255", borderColor: "green" }}
              onClick={() => {
                survey_model.completeLastPage();
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const readOnlyHeader = () => {
    if (!isReadOnly) return null;
    return (
      <div className="header-readonly">
        <div className="title">
          {"This survey is completed, read only mode"}
        </div>
      </div>
    );
  };

  const survey = (
    <>
      <div className="header-warning">Empty page</div>
      {readOnlyHeader()}
      <div className="survey-wrap">
        {showCustomNav()}
        {!isCompleted && (
          <SurveyPlugin.Survey
            model={survey_model}
            onCompleting={(answers) => setAnswerState(answers.data)}
            onComplete={onComplete}
            currentPageNo={surveyPage}
            onCurrentPageChanged={onPageSwitch}
            onValueChanged={onSurveyAnswerChange}
          />
        )}
        {isCompleted && showCustomCompletedMessage()}
      </div>
    </>
  );

  let startButtonStyle = { width: "100%", marginTop: "30px", minWidth: "30vw" };

  if (isReadOnly) {
    startButtonStyle.background = "orange";
    startButtonStyle.borderColor = "darkorange";
  }

  const surveyWelcomeScreen = (
    <div className="version_selector">
      <div className={"start-wrap"}>
        <h3 style={{ marginBottom: "10px" }}>
          {getTranslation(props?.template?.title)}
        </h3>
        <div className={"survey-description"}>
          {getTranslation(props?.template?.description)}
        </div>
        <Button
          name="long_survey"
          label={isReadOnly ? "Review Questionnaire" : "Start survey"}
          style={startButtonStyle}
          onClick={() => {
            if (surveyShortVersion) {
              surveyData.title += " (" + t("Short version") + ")";
            }
            surveyStarted(true);
          }}
        />
        {props.template.shortVersion && hasAtleastOneRequired && (
          <Toggle1Icon
            className="short"
            name="version"
            label="Short version"
            onChange={() => {
              setShortSurveyVersion(!surveyShortVersion);
            }}
            value={surveyShortVersion}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="survey">
      <NativeDialog />
      <Notification />
      <div className="header">
        <div className="logo">
          <img alt="logo" src={logo} />
        </div>
        <div className="title">SURVEY CLIENT</div>
      </div>
      {!isStarted ? surveyWelcomeScreen : survey}
      <SurveyFooter />
    </div>
  );
};

export default Survey;
